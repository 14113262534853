<template>
  <v-row class="my-0" justify="center" align="center">
    <v-col cols="auto">
      <of-selection v-model="internalValue" />
    </v-col>
    <v-col cols="auto">
      <v-btn
        color="accent"
        :loading="loading"
        :disabled="!canValidate"
        @click="$emit('input', internalValue)"
      >
        Valider
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import Helpers from 'src/utils/helpers'
import OfSelection from './OfSelection'

export default {
  name: 'FilterSelection',
  components: { OfSelection },
  props: {
    value: {
      type: [Array, String, Object],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    internalValue: null,
  }),
  computed: {
    canValidate () {
      if (this.internalValue) return true
      return false
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
      },
    },
  },
}
</script>
