<template>
  <v-autocomplete
    label="OF"
    outlined
    hide-details
    dense
    :value="value"
    :filter="customFilter"
    :items="batchList"
    :loading="loading"
    item-value="_id"
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
        v-bind="attr"
        :input-value="selected"
        color="primary"
        class="pl-1 white--text"
        v-on="on"
      >
        <div
          class="rounded-pill white primary--text mr-1 px-2"
          style="padding: 2px"
        >
          {{ item.number }}
        </div>
        <span v-text="item.partNumber.name" />
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content style="max-width: 60px">
        <div
          style="min-width:52px; max-width:52px"
          class="rounded-xl primary white--text py-1 px-2 text-center"
        >
          {{ item.number }}
        </div>
      </v-list-item-content>
      <v-list-item-content>
        <v-list-item-title v-text="item.partNumber.name" />
        <v-list-item-subtitle v-text="item.partType.name" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import Repositories from 'src/repositories'
import { mapActions } from 'vuex'

export default {
  name: 'OfSelection',
  components: { },
  props: {
    value: {
      type: [Array, String],
      default: null,
    },
  },
  data: () => ({
    batchList: [],
    loading: false,
  }),
  async mounted () {
    try {
      this.loading = true
      const list = await Repositories.batch.getList()
      this.batchList = list.filter(batch => batch.partType._id !== '5b4c8cb61d33360c2526d87f')
    }
    catch (error) {
      this.showErrorNotification(Repositories.parseErrorMessage(error))
    }
    finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    customFilter (item, queryText) {
      const lowerQueryText = queryText.toLowerCase()
      const batchNumber = item.number.toString()
      const partNumber = item.partNumber.name.toLowerCase()
      const partType = item.partType.name.toLowerCase()

      return batchNumber.indexOf(lowerQueryText) > -1
          || partNumber.indexOf(lowerQueryText) > -1
          || partType.indexOf(lowerQueryText) > -1
    },
  },
}
</script>
<style scoped>
.v-avatar v-list-item__avatar {
  width: 52px !important;
}
</style>
